/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The following is a code block with JavaScript language syntax highlighting."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "import React from 'react';\n")), "\n", React.createElement(_components.p, null, "Supports multiple languages."), "\n", React.createElement(_components.p, null, "The following is a code block with diff. Lines with ", React.createElement(_components.code, null, "+"), " highlighted in green shade indicating an addition. Lines with ", React.createElement(_components.code, null, "-"), " highlighted in red shade indicating a deletion."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "- const data = ['1','2'];\n+ const data = [1,2];\n")), "\n", React.createElement(_components.h2, null, "Live Editing example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<button className={'btn btn-default'}>Change my text</button>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
